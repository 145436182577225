/* Generated by grunt-webfont */

@font-face {
  font-family: 'icons';
  src: url('./icons.eot?7aedd013e072110dcc3400637dd0f69d');
  src: url('./icons.eot?#iefix') format('embedded-opentype'),
    url('./icons.woff?7aedd013e072110dcc3400637dd0f69d') format('woff'),
    url('./icons.ttf?7aedd013e072110dcc3400637dd0f69d') format('truetype');
  font-weight: normal;
  font-style: normal;
}

.ag-icon {
  font-family: 'icons';

  display: inline-block;
  vertical-align: middle;
  line-height: 1;
  font-weight: normal;
  font-style: normal;
  speak: none;
  text-decoration: inherit;
  text-transform: none;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Icons */

.ag-icon-arrow-down:before {
  content: '\f101';
}

.ag-icon-audience:before {
  content: '\f102';
}

.ag-icon-audio:before {
  content: '\f103';
}

.ag-icon-camera-off:before {
  content: '\f104';
}

.ag-icon-camera:before {
  content: '\f105';
}

.ag-icon-disable-video:before {
  content: '\f106';
}

.ag-icon-invalid:before {
  content: '\f107';
}

.ag-icon-leave:before {
  content: '\f108';
}

.ag-icon-mic-off:before {
  content: '\f109';
}

.ag-icon-mic:before {
  content: '\f10a';
}

.ag-icon-remove-pip:before {
  content: '\f10b';
}

.ag-icon-screen-share:before {
  content: '\f10c';
}

.ag-icon-switch-display:before {
  content: '\f10d';
}

.ag-icon-valid:before {
  content: '\f10e';
}

.ant-table-container table > thead > tr:first-child th:first-child {
  width: 3%;
}

.ant-layout {
  display: flex;
  flex: auto;
  flex-direction: column;
  min-height: 0;
  background: #f7f7f7;
}

.ant-table-thead > tr > th {
  font: normal normal normal 14px/19px Nunito Sans;
  color: #868686;
  font-weight: 500;
  text-align: left;
  background: #f7f7f7;
  border-bottom: 1px solid #f7f7f7;
  padding: 8px 5px;
}

.ant-table-tbody > tr > td {
  border-bottom: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  padding: 8px 5px;
}

.ant-table-tbody > tr > td:hover {
  border-bottom: 1px solid #f7f7f7;
  background-color: #f7f7f7;
  padding: 2px 5px;
}

.ant-table-pagination.ant-pagination {
  display: none;
}

table tr th.ant-table-selection-column,
table tr td.ant-table-selection-column {
  padding-right: 8px;
  padding-left: 8px;
  text-align: center;
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  /* background: #dcf4ff; */
}

.ant-table-tbody > tr.ant-table-row:hover > td {
  /* background: #fafafa; */
}

.ant-checkbox-wrapper {
  /*box-sizing: border-box;
  margin: 0;
  padding: 0;
  color: rgba(0, 0, 0, 0.85);
  font-size: 14px;
  font-variant: tabular-nums;
  line-height: 1.5715;
  list-style: none;
  font-feature-settings: 'tnum', "tnum";
  display: inline-flex;
  align-items: baseline;
  line-height: unset;
  cursor: pointer;
  margin-top: 1px;*/
}

.ant-table-tbody > tr.ant-table-row-selected:hover > td {
  background: #fff;
}

.ant-table-tbody > tr.ant-table-row-selected > td {
  background: #f7f7f7;
  border-color: #f7f7f7;
}

.ant-table-small .ant-table-thead > tr > th {
  background-color: #f7f7f7;
}

.ant-table.ant-table-small .ant-table-title,
.ant-table.ant-table-small .ant-table-footer,
.ant-table.ant-table-small .ant-table-thead > tr > th,
.ant-table.ant-table-small .ant-table-tbody > tr > td,
.ant-table.ant-table-small tfoot > tr > th,
.ant-table.ant-table-small tfoot > tr > td {
  padding: 8px 0px;
}

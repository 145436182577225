.input-label:focus-within {
  color: var(--darkBlueGray);
}

.ant-btn-primary {
  color: #fff;
  background: #6963a9;
  border-color: var(--darkCyan);
  text-shadow: 0 -1px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 2px 0 rgb(0 0 0 / 5%);
}

.ant-btn-primary:hover,
.ant-btn-primary:focus {
  color: #fff;
  background: var(--darkCyan);
  border-color: var(--darkCyan);
}

.container-back-to-selection {
  background-color: #f7f7f7;
}

.teste::after {
  border: none;
}

.ant-menu-inline .ant-menu-item::after {
  border-right: none;
}

.space-btw {
  min-height: 4%;
  background-color: #f7f7f7;
}

@media (min-width: 770px) {
  .container-app-content-sider {
    width: 80%;
  }
}

@media (max-width: 770px) {
  .container-app-content-sider {
    min-width: 98%;
  }
}

.container-app-content-sider {
  display: flex;
  height: 100%;
}

@media (min-width: 900px) {
  .container-receptionist-content {
    margin-left: 5.5%;
  }
}

@media (max-width: 625px) {
  .container-receptionist-content {
    margin-top: 5%;
  }
}

@media (min-width: 990px) {
  .ant-layout-sider,
  .ant-layout-sider-dark,
  .ant-layout-sider-children,
  .ant-layout-sider-below {
    min-width: 240px;
  }
}

.container-receptionist-content {
  display: flex;
  flex-direction: column;
  width: 95.5%;
  height: 100%;
}

.ant-layout-sider-children {
  flex-direction: column;
}

.ant-layout-sider-children {
  background-color: #f7f7f7;
  flex-direction: column;
}

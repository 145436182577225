input {
  border-radius: 4px;
}

.ant-input {
  border-radius: 4px;
}

.ant-btn.ant-dropdown-trigger {
  border-radius: 4px;
}

.ant-picker {
  border-radius: 4px;
}

.ant-select-selector {
  border-radius: 4px;
}

.div {
  border-radius: 4px;
}

button > span {
  font-weight: bold;
}

aside {
  background-color: #000;
}

.container-app-content-sider {
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 7rem;
  padding-right: 7rem;
}

.container-app-content-sider-0rem {
  display: flex;
  height: 100%;
  width: 100%;
  padding-left: 0rem;
  padding-right: 1rem;
}

@media screen and (max-width: 992px) {
  .container-app-content-sider {
    display: flex;
    height: 100%;
    width: 100%;
    padding-left: 0rem;
    padding-right: 0rem;
  }
}

.ant-radio-button-wrapper:not(:first-child)::before {
  position: absolute;
  top: -1px;
  left: -1px;
  display: block;
  box-sizing: content-box;
  width: 1px;
  height: 100%;
  padding: 1px 0;
  background-color: #d9d9d900;
  transition: background-color 0.3s;
  content: '';
}

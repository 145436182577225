.container-patient-controller {
  display: flex;
  flex-direction: column;
  height: 173px;
  width: 100%;
  background-color: #f7f7f7;
}

.container-white-background {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 15px #0000000f;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  font-size: 16px;
}

.container-patient-info {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 5%;
  padding-top: 7%;
  width: 100%;
}

.container-patient-controller-btns {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: auto;
  width: 90%;
  height: 50%;
  margin-top: 1%;
  margin-left: 10%;
}

.input-label:focus-within {
  color: var(--darkBlueGray);
}

.fc .fc-col-header-cell-cushion {
  display: inline-block;
  padding: 2px 4px;
  text-align: center;
  font: normal normal normal 14px/19px Nunito Sans;
  letter-spacing: 0px;
  color: #868686;
  text-transform: uppercase;
}

.fc .fc-daygrid-day-number {
  position: relative;
  z-index: 4;
  padding: 4px;
  color: var(--unnamed-color-575757);
  text-align: left;
  font: normal normal bold 16px/20px Nunito Sans;
  letter-spacing: 0px;
  color: #575757;
  opacity: 1;
  width: 100%;
}

/*
.fc .fc-view-harness-active > .fc-view {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-x: scroll;
}

.fc .fc-daygrid-day-frame {
  position: relative;
  min-height: 100%;
  min-width:50px;
}*/
@media (max-width: 700px) {
  a {
    color: #6963a9;
    text-decoration: none;
    background-color: transparent;
    outline: none;
    cursor: pointer;
    transition: color 0.3s;
    -webkit-text-decoration-skip: objects;
    font-size: 10px;
  }

  .iAakUz {
    color: var(--davysGrey);
    text-align: start;
    font: normal normal normal 15px/19px Nunito Sans;
    padding-left: 4px;
    text-transform: capitalize;
    cursor: pointer;
    font-size: 10px;
  }

  Total.fc .fc-list-table tr > * {
    border-left: 0;
    border-right: 0;
    font-size: 10px;
  }
}

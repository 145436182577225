.container-patient-controller {
  flex-direction: column;
  width: 100%;
  max-height: 145px;
  background-color: #f7f7f7;
}

.container-white-background {
  flex-direction: column;
  width: 100%;
  background-color: white;
  box-shadow: 0px 0px 15px #0000000f;
  border-radius: 8px 8px 0px 0px;
  opacity: 1;
  font-size: 16px;
}

.container-patient-info {
  align-items: center;
  justify-content: space-around;
  padding: 5%;
  width: 100%;
}

.container-patient-controller-btns {
  flex-direction: column;
  justify-content: space-around;
  width: 90%;
  max-height: 50px;
  margin-top: 1%;
  margin-left: 10%;
}

@media (min-width: 550px) {
  .questionFormAddProtocol {
    flex-direction: row;
  }
}

@media (max-width: 550px) {
  .questionFormAddProtocol {
    flex-direction: column;
  }
}

.questionFormAddProtocol {
  display: flex;
  width: 50%;
  min-width: 376px;
  height: 30px;
  align-items: center;
  justify-content: space-between;
}

.containerElements {
  display: flex;
  align-items: center;
  width: 50%;
}

.textInfo {
  color: '#999999';
  font-size: 14px;
  color: var(--davysGrey);
  margin-left: 4px;
}

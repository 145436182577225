.ant-modal-footer {
  border-top: 0;
  border-radius: 10px;
}

.ant-modal-header {
  border-radius: 10px;
  border-bottom: 0px;
}

.ant-modal-content {
  border-radius: 10px;
  padding: 10px;
  padding-bottom: 20px;
}

.ant-modal-title {
  font-size: 20px;
  color: #868686;
}

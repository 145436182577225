.fc-timegrid-slot.fc-timegrid-slot-lane {
  background: #f8f9fa;
}

.fc-event {
  max-width: 230px;
}

.fc-col-header {
  background: #f7f7f7;
}

.fc-timegrid-event {
  border-radius: 10px;
}

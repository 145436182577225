.create-new-form-protocol-setup {
  display: flex;
  flex-direction: column;
  min-width: 100%;
  min-height: 100%;
}

.title-search-form-protocol {
  display: flex;
  flex-direction: column;
  height: 60px;
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 1rem;
}

.subtitle-form-protocol {
  color: var(--davysGrey);
  font-size: 16px;
}

h5 {
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 2rem;
  margin-bottom: 1rem;
}

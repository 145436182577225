@media (max-width: 610px) {
  .container-click-elements-protocol {
    flex-direction: column;
    margin-bottom: 18px;
  }
}

.container-click-elements-protocol {
  display: flex;
  align-items: center;
  height: 60px;
  justify-content: space-between;
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
}

.container-btns-controller-content-visualization-protocol {
  display: flex;
  align-items: center;
  height: 60px;
  color: var(--davysGrey);
  text-align: left;
  font: normal normal normal 20px/27px Nunito Sans;
  letter-spacing: 0px;
  opacity: 1;
}

@media (max-width: 610px) {
  .container-btns-controller-content-visualization-protocol {
    flex-direction: column;
    height: auto;
    margin-bottom: 10px;
  }
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item-selected,
.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-submenu-selected {
  border-bottom: none;
}

.ant-menu-horizontal:not(.ant-menu-dark) > .ant-menu-item:hover {
  border-bottom: none;
}

.ant-menu-submenu,
.ant-menu-submenu-horizontal,
.ant-menu-submenu,
.ant-menu-submenu-open,
.ant-menu-overflowed-submenu,
.ant-menu-submenu-active,
.ant-menu-submenu-popup .ant-menu-light {
  color: white;
}

.ant-menu-submenu-title {
  color: white;
}

.ant-menu-vertical .ant-menu-item:not(:last-child),
.ant-menu-vertical-left .ant-menu-item:not(:last-child),
.ant-menu-vertical-right .ant-menu-item:not(:last-child),
.ant-menu-inline .ant-menu-item:not(:last-child) {
  margin-bottom: 0px;
}

.ant-menu-item ant-menu-item-only-child ant-menu-item-selected {
  padding: 0px;
}

element.style {
  color: white;
  background-color: rgb(39, 94, 91);
  border-radius: 8px;
  border-bottom: none;
  width: 100px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px 0px 0px 0px;
  margin: 0px;
}

#ag-canvas {
  height: 100%;
  width: 100%;
}

.ag-item :first-child {
  border-radius: 6px;
}

.ag-item {
  border-radius: 6px;
  border: 2px #00b6ed solid;
  background: url('../assets/agora/avatar.png') center no-repeat;
  height: 100%;
  width: 100%;
}

#ag-resize-container {
  background-image: none !important;
  background-color: black;
  display: flex;
  justify-content: center;
  height: center;
}

#ag-resize-container .ag-item {
  border: none !important;
}

/* button group */

.ag-btn-group {
  height: 50px;
  width: 100%;
  position: fixed;
  bottom: 0;
  left: 0;
  background: transparent;
  z-index: 12;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ag-btn-group-full {
  height: 80px;
  width: 35%;
  margin: 0 auto;
  margin-left: 30%;
  position: absolute;
  bottom: 0;
  background: transparent;
  z-index: 11;
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.ag-btn-group:hover,
.ag-btn-group.active {
  background: #868686;
}

.ag-btn-group-full:hover,
.ag-btn-group-full.active {
  background: rgba(18, 73, 99, 0.897);
}

.ag-btn {
  z-index: 12;
  opacity: 0;
  background: white;
  width: 55px;
  height: 30px;
  cursor: pointer;
  font-size: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-top: none;
  border-bottom: none;
}

.ag-btn img {
  width: 18px;
  height: 18px;
}

.ag-btn:hover {
  border: 1px solid #00b6ed;
}

.ag-btn.disabled,
.ag-btn.disabled:hover {
  color: #637c8e;
  cursor: not-allowed;
}

.ag-btn-group:hover .ag-btn,
.ag-btn-group.active .ag-btn {
  opacity: 1;
}

.ag-btn-group-full:hover .ag-btn,
.ag-btn-group-full.active .ag-btn {
  opacity: 1;
}

.ag-btn.off.videoControlBtn .ag-icon-camera {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera-off {
  display: none;
}

.ag-btn.videoControlBtn .ag-icon-camera {
  display: inline-block;
}

.ag-btn.off.videoControlBtn .ag-icon-camera-off {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic-off {
  display: none;
}

.ag-btn.audioControlBtn .ag-icon-mic {
  display: inline-block;
}

.ag-btn.off.audioControlBtn .ag-icon-mic-off {
  display: inline-block;
}
